/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm, scale } from "../utils/typography"

const bioName = "Barry Brown"
const bioDescription = "Leadership Dev, Singularity University"


function BioBarry(props) {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(1),
              marginTop: rhythm(1.5),
              marginRight: rhythm(.5)
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={bioName}
              style={{
                marginRight: rhythm(.3),
                marginBottom: 0,
                minWidth: 50,
              }}
              imgStyle={{
                borderRadius: `5%`,
              }}
            />
            <div
              style={{
                maxWidth: '175px'
              }}
            >
              <h4
                style={{
                  marginBottom: rhythm(.5)
                }}
              >
                {bioName}
              </h4>
              <p
                style={{
                  ...scale(-.25),
                  lineHeight: 1.5
                }}
              >
                {bioDescription}
              </p>
            </div>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQueryBarry {
    avatar: file(absolutePath: { regex: "/barry.jpg/" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default BioBarry
