const colors = {
  // original palette by @SachaG
  // @see https://www.figma.com/file/J6IYJEtdRmwJQOrcZ2DfvxDD/Gatsby
  humans: '#128E97', // teal'ish
  rust: `#E34A06`,
  turquoise: `#31CCE8`,
  accentgreen: `#C1C138`,
  niceyellow: `#F9BD01`,
  darkgreen: '#417026',
  success: `#37b635`,
  warning: `#ec1818`,
  white: `#ffffff`,
}

export default colors
