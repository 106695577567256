// Forms suck.
// Used gatsby-plugin-mailchimp.
// The used component from Gatsby website. Old commit with styles from recent. Old below:
// from https://github.com/gatsbyjs/gatsby/blob/6e688a382485ddae4de4b35799e46c71c1f3b9e4/www/src/components/email-capture-form.jsjs

import React from "react"
import styled from "@emotion/styled"
import ReactGA from 'react-ga'

import { MdFileDownload } from "react-icons/md"

import { rhythm } from "../utils/typography"
import { space, breakpoints } from "../utils/presets"
import { buttonStyles } from "../utils/styles"


const Submit = styled(`button`)`
  ${buttonStyles.default};
  font-size: 1.125rem;
  width: auto;
  margin-top: ${rhythm(space[3])};

  span {
    align-items: center;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }

  ${breakpoints.lg} {
    width: auto;
    margin-top: ${rhythm(1.5)};
  }
`

class DownloadButton extends React.Component {

  buttonEvent() {
    // Detect each click of the financial PDF
    ReactGA.event({
      category: 'DownloadPDF',
      action: 'User clicked button to go to download form page'
    })
  }

  render() {
    const { submitMessage, pageLink } = this.props

    return (
      <div>
        <a
         href={pageLink}
         style={{
           textDecoration: 'none'
         }}
         onClick={this.buttonEvent}
         id="button-download-page"
        >
          <Submit
            type="submit"
          >
            <span>
              {submitMessage}
              <MdFileDownload />
            </span>
          </Submit>
        </a>
      </div>
    )
  }
}

DownloadButton.defaultProps = {
  submitMessage: `Let's Talk!`,
  pageLink: `/file-download`
}

export default DownloadButton
