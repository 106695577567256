// Forms suck.
// Used gatsby-plugin-mailchimp.
// The used component from Gatsby website. Old commit with styles from recent. Old below:
// from https://github.com/gatsbyjs/gatsby/blob/6e688a382485ddae4de4b35799e46c71c1f3b9e4/www/src/components/email-capture-form.jsjs

import React from "react"
import styled from "@emotion/styled"

import { MdSend } from "react-icons/md"

import { rhythm } from "../utils/typography"
import { space, breakpoints } from "../utils/presets"
import { buttonStyles } from "../utils/styles"


const Submit = styled(`button`)`
  ${buttonStyles.default};
  font-size: 1.125rem;
  width: auto;
  margin-top: ${rhythm(space[3])};

  span {
    align-items: center;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }

  ${breakpoints.lg} {
    width: auto;
    margin-top: ${rhythm(1.5)};
  }
`

class MailButton extends React.Component {

  render() {
    const { submitMessage, mailTo } = this.props

    return (
      <div>
        <a
         href={mailTo}
         style={{
           textDecoration: 'none'
         }}
        >
          <Submit
            type="submit"
          >
            <span>
              {submitMessage}
              <MdSend />
            </span>
          </Submit>
        </a>
      </div>
    )
  }
}

MailButton.defaultProps = {
  submitMessage: `Let's Talk!`,
  mailTo: `mailto:ethodesign@human-ethos.com"`
}

export default MailButton
