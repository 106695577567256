const colors = require(`./colors`).default

module.exports = {
  colors,
  breakpoints: {
    Xs: `(min-width: 400px)`,
    xs: `@media (min-width: 400px)`,
    Sm: `(min-width: 550px)`,
    sm: `@media (min-width: 550px)`,
    Md: `(min-width: 750px)`,
    md: `@media (min-width: 750px)`,
    Lg: `(min-width: 1000px)`,
    lg: `@media (min-width: 1000px)`,
    Xl: `(min-width: 1200px)`,
    xl: `@media (min-width: 1200px)`,
    Xxl: `(min-width: 1600px)`,
    xxl: `@media (min-width: 1600px)`,
  },
  transition: {
    curve: {
      default: `cubic-bezier(0.4, 0, 0.2, 1)`,
    },
    speed: {
      default: `250ms`,
      fast: `100ms`,
      slow: `350ms`,
    },
  },
  space: [
    0,
    1 / 6,
    1 / 3,
    1 / 2,
    2 / 3,
    5 / 6,
    1,
    4 / 3,
    5 / 3,
    2,
    7 / 3,
    8 / 3,
    3,
  ],
}
