import React from "react"

import DownloadButton from "../components/download-button"

import { rhythm, scale } from "../utils/typography"
import color from "../utils/colors"


class Services extends React.Component {

  render() {

    // using https://www.npmjs.com/package/react-text-loop
    return (
      <div>
        <h2
        style = {{
          ...scale(1.75),
          marginTop: rhythm(2.25)
        }}
        >
          Ethos<sup style={{fontWeight: 'normal'}}>*</sup> = Retention Growth <br />
          <span
            style={{
              fontSize: '60%'
            }}
          >
            Employees. And <em>then</em> Customers.
          </span>
        </h2>

        <p style={{marginBottom: rhythm(.25)}}>
        <strong>When it comes to ROI, company culture might be the best
        investment you can make.</strong> But building a great culture is
        difficult at best. And these days there are all kinds of new, complex
        ways morale is getting kicked in the gut. <em>Hard.</em>
        </p>
        <ul>
          <li style={{marginBottom:rhythm(0)}}>
            <span>backlash by the general public against a "Silicon Valley mindset"</span>
          </li>
          <li style={{marginBottom:rhythm(0)}}>
            misuse, and illicit use, of products and services
          </li>
          <li>
            harmful, unforeseen effects of technology innovation
          </li>
        </ul>
        <p>
          These don’t just cost you customers and market
          share. <strong style={{backgroundColor:'yellow'}}>They cost you
          employee satisfaction and turnover.</strong>
        </p>

        <DownloadButton
          submitMessage="Download Services PDF"
          pageLink="/download-file"
        />

        <p style={{...scale(-.5),marginTop:rhythm(1)}}>
          *Ethos is a precursor for cultural norms. It includes things
          like <em>purpose, values,</em> and <em>relationships</em>.
        </p>

        <h3
          style = {{
            color: color.rust,
            ...scale(1),
            marginBottom: rhythm(.25)
          }}
        >
          We do Fitness for Team Health
        </h3>

        <p>
        You need an organizational ethos that yields a robust culture and
        robust teams. And one that additionally produces products or services
        that build trust and do right by your customers.
        </p>
        <p>
          <strong>In both cases, it turns out the answer lies in Ethical
          Theory.<sup>*</sup></strong> Not the kind of <em>check-boxes, good-bad-right-wrong,
          esoteric-out-there</em> ethics. But
          the <em>improve-relationships-and-enhance-outcomes-through-behavior</em> kind.
        </p>
        <p>
          Ethics can feel daunting. Working with us is simple.
        </p>

        <p style={{...scale(-.5)}}>
          <sup>*</sup>A lot of great research on this topic. Let us know if you
          want to talk and get nerdy with us.
        </p>

        <h3
          style = {{
            color: color.rust,
            ...scale(1),
            marginBottom: rhythm(.25)
          }}
        >
          <em>Ethos</em> Practice makes <em>Product</em> Progress
        </h3>

        <p style={{marginBottom: rhythm(.25)}}>
        <strong>Our framework, Etho-design™, builds ethics agility through practices
        integrated with your existing processes.</strong> We partner with you to deliver real ROI:
        </p>
        <ol>
          <li style={{marginBottom:rhythm(0)}}>cultivate better products and teams</li>
          <li style={{marginBottom:rhythm(0)}}>prove to the market you care (ie build trust)</li>
          <li style={{marginBottom:rhythm(0)}}>and preempt public push back (the right way).</li>
        </ol>
        <p>
          We offer a variety of interactive training options including seminars
          and workshops. And we are available for coaching and consulting to
          target specific needs and outcomes.
        </p>
        <h3
          style={{
            marginBottom: rhythm(1),
            fontWeight: 'normal'
          }}
        >
          <em>Ready to explore the next step that’s a right fit?</em> Let's
          find time to schedule a video call.
        </h3>

      </div>
    )
  }
}

export default Services
