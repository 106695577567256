import { rhythm } from "./typography"
import { keyframes } from "@emotion/core"

import {colors, transition, space} from "./presets"

const stripeAnimation = keyframes({
  "0%": { backgroundPosition: `0 0` },
  "100%": { backgroundPosition: `${rhythm(space[7])} ${rhythm(space[11])}` },
})

export const buttonStyles = {
  default: {
    alignItems: `center`,
    backgroundColor: colors.humans,
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: `solid`,
    borderColor: colors.humans,
    color: colors.white,
    cursor: `pointer`,
    display: `flex`,
    fontWeight: `bold`,
    flexShrink: 0,
    textDecoration: `none`,
    WebkitFontSmoothing: `antialiased`,
    whiteSpace: `nowrap`,
    padding: `${rhythm(space[1])} ${rhythm(space[2])}`,
    backgroundSize: `${rhythm(space[7])} ${rhythm(space[7])}`,
    transition: `all ${transition.speed.default} ${transition.curve.default}`,
    ":hover, :focus": {
      backgroundColor: colors.humans,
      color: colors.white,
      animation: `${stripeAnimation} 2.8s linear infinite`,
      borderColor: colors.humans,
    },
    ":focus": {
      outline: 0,
      boxShadow: `0 0 0 ${rhythm(space[1])} rgba(249,189,1,0.25)`,
    },
    ":after": { content: `''`, display: `block` },
    "& svg": { marginLeft: `.2em` },
  }
}

export const formInput = {
  backgroundColor: colors.white,
  border: `1px solid ${colors.humans}`,
  width: '100%',
  display: `block`,
  borderRadius: 2,
  color: colors.humans,
  padding: `${rhythm(space[1])} ${rhythm(space[2])}`,
  verticalAlign: `middle`,
  transition: `all ${transition.speed.default} ${transition.curve.default}`,
  "::placeholder": {
    color: colors.humans,
    fontWeight: '300',
    opacity: 1,
  },
}
