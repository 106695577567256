import React from "react"
import { graphql } from "gatsby"

import { FaTwitter } from "react-icons/fa"
import { FaLinkedin } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailCaptureForm from "../components/email-capture-form"
import Services from "../components/services"
import Team from "../components/team"
import MailButton from "../components/mail-button"

import { rhythm, scale } from "../utils/typography"
import color from "../utils/colors"


class Homepage extends React.Component {

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Retention Growth Hacking Services | Employees, then Customers"
          keywords={[`ethics`, `tech`, `products`, `teams`, `training`, `coaching`]}
        />
        <h1
          style={{
            ...scale(1.75),
            marginBottom: rhythm(1.5),
            lineHeight: 1
          }}
        >
          Culture Skill-Building for Product Leaders and Teams
        </h1>

        <h2
          style={{
            ...scale(1),
            color: '#F05F57'
          }}
        >
          Upcoming Event August 22nd
        </h2>

        <h4
          style={{
            marginTop: rhythm(-1),
            marginBottom: rhythm(1.25),
            fontWeight: 'normal'
          }}
        >
          @Runway Innovation Hub, San Francisco
        </h4>

        <h3
          style={{
            marginBottom: rhythm(1),
            fontWeight: '600'
          }}
        >
          Shaping Culture:<br />
          <span
            style={{
              fontStyle: 'italic',
              fontWeight: 'normal'
            }}
          >
          A skill-based framework for team performance
          </span>
        </h3>
        <div
          style = {{
            marginLeft: '20px'
          }}
        >
          <p>
            An interactive presentation for leaders and members of teams
            creating products or services of any kind. Anyone interested is
            welcome to attend, of course.
          </p>
          <h4
            style = {{
              marginBottom: rhythm(.25)
            }}
          >
            August 22, 10:00am - 12:30pm<br />
          </h4>
          <small style={{fontStyle:'italic'}}>
            regular priced tickets $65<br />
            optional lunch discussion add-on ($15) from 12:30 - 1:30
          </small>
          <h4
            style = {{
              marginTop: rhythm(1),
              marginBottom: rhythm(0)
            }}
          >
              Registration and Details:
          </h4>
          <p>
          <a href="https://www.eventbrite.com/e/shaping-culture-a-skill-based-framework-for-team-performance-tickets-68656203553"
            target="_blank" rel="noopener noreferrer">
            Eventbrite Registration
          </a>
          </p>
        </div>

        <EmailCaptureForm
          signupMessage="Receive updates about future events, workshops, and news:"
          confirmMessage="Success! Keep a lookout in your inbox for future updates."
        />

        <a
          href="https://linkedin.com/company/human-ethos"
          target="_blank"
          rel="noopener noreferrer"
          title="LinkedIn human(Ethos)"
          css={{
            color: color.niceyellow,
            marginRight: '20px'
          }}
        >
          <FaLinkedin size={36} />
        </a>

        <a
          href="https://twitter.com/human_ethos"
          target="_blank"
          rel="noopener noreferrer"
          title="Twitter human(Ethos)"
          css={{
            color: color.niceyellow,
          }}
        >
          <FaTwitter size={36} />
        </a>

        <h2
          style={{
            marginTop: rhythm(1.5),

          }}
        >
          Previous Event
        </h2>
        <h4
          style={{
            marginTop: rhythm(-1),
            marginBottom: rhythm(1.25),
            fontWeight: 'normal'
          }}
        >
          @GitHub, San Francisco, April 13
        </h4>

        <div
          style = {{
            marginLeft: '20px'
          }}
        >
          <h3
            style={{
              marginBottom: rhythm(1),
              fontWeight: 'normal'
            }}
          >
          Ethics, Tech, and Teams:<br />
          <span
            style={{
              fontStyle: 'italic'
            }}
          >
            Shaping the People & Products that are Shaping the World
          </span>
          </h3>
          <h4
            style = {{
              marginTop: rhythm(1),
              marginBottom: rhythm(0)
            }}
          >
            Description and Details <span style={{fontWeight:'normal'}}>(click "View Details" on next page)</span>:
          </h4>
          <p>
          <a  href="https://ethics-tech-teams.eventbrite.com"
          target="_blank" rel="noopener noreferrer">
            ethics-tech-teams.eventbrite.com
          </a>
          </p>
        </div>

        <Services />

        <MailButton
          submitMessage="human(Contact)"
          mailTo="mailto:ethodesign@human-ethos.com?subject=I'd like to discuss your services"
        />

        <Team />

        <MailButton
          submitMessage="human(Connection)"
          mailTo="mailto:thecollective@human-ethos.com?subject=I'm interested in your Collective"
        />

      </Layout>
    )
  }
}

export default Homepage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
