// Forms suck.
// Used gatsby-plugin-mailchimp.
// The used component from Gatsby website. Old commit with styles from recent. Old below:
// from https://github.com/gatsbyjs/gatsby/blob/6e688a382485ddae4de4b35799e46c71c1f3b9e4/www/src/components/email-capture-form.jsjs

import React from "react"
import styled from "@emotion/styled"

import { MdSend } from "react-icons/md"

import { rhythm } from "../utils/typography"
import { colors, space, breakpoints } from "../utils/presets"
import { formInput } from "../utils/styles"
import { buttonStyles } from "../utils/styles"
import addToMailchimp from "gatsby-plugin-mailchimp"

const SingleLineInput = styled(`input`)`
  ${formInput};
  :focus {
    border-color: ${colors.humans};
    outline: 0;
    box-shadow: 0 0 0 ${rhythm(space[1])} rgba(18,142,151,0.25);
  }
`

// this one.... grrrrr
const InnerFormDiv = styled(`div`)`
  width: 100%;
  ${breakpoints.md} {
    display: block;
  }
  ${breakpoints.lg} {
    display: inherit;
  }
`

const ErrorMessage = styled(`div`)`
  color: ${colors.warning};
  margin: ${rhythm(space[2])} 0 0;
  display: block;
`

const SuccessMessage = styled(`div`)`
  color: ${colors.darkgreen};
  margin-top: ${rhythm(1)};
`

const Submit = styled(`button`)`
  ${buttonStyles.default};
  font-size: 1.125rem;
  width: auto;
  margin-top: ${rhythm(space[3])};

  span {
    align-items: center;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }

  ${breakpoints.lg} {
    width: auto;
    margin-top: 0;
    margin-left: ${rhythm(space[2])};
  }
`

class EmailCaptureForm extends React.Component {
  constructor() {
    super()
    this.state = {
      email: ``,
    }
  }

  // Update state each time user edits their email address
  _handleEmailChange = e => {
    this.setState({ email: e.target.value })
  }

  // Post to MC server & handle its response
  _postEmailToMailchimp = (email, attributes) => {
    addToMailchimp(email, attributes)
      .then(result => {
        // Mailchimp always returns a 200 response
        // So we check the result for MC errors & failures
        if (result.result !== `success`) {
          this.setState({
            status: `error`,
            msg: result.msg,
          })
        } else {
          // Email address succesfully subcribed to Mailchimp
          this.setState({
            status: `success`,
            msg: result.msg,
          })
        }
      })
      .catch(err => {
        // Network failures, timeouts, etc
        this.setState({
          status: `error`,
          msg: err,
        })
      })
  }

  _handleFormSubmit = e => {
    e.preventDefault()
    e.stopPropagation()

    this.setState(
      {
        status: `sending`,
        msg: null,
      },
      // setState callback (subscribe email to MC)
      this._postEmailToMailchimp(this.state.email, {
        pathname: document.location.pathname,
      })
    )
  }

  render() {
    const { signupMessage, confirmMessage } = this.props

    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(1)
        }}
      >
        {this.state.status === `success` ? (
          <SuccessMessage>{confirmMessage}</SuccessMessage>
        ) : (
          <div>
            <p
              style={{
                marginTop: rhythm(1),
                marginBottom: rhythm(.25)
              }}
            >
              {signupMessage}
            </p>
            <form
              id="email-capture"
              method="post"
              noValidate
              style={{
                margin: 0,
                display: 'flex'
              }}
            >
              <InnerFormDiv>
                <SingleLineInput
                  id='email'
                  type="email"
                  name="email"
                  aria-label='Email'
                  placeholder="your.email@example.com"
                  onChange={this._handleEmailChange}
                />
                <Submit
                  type="submit"
                  onClick={this._handleFormSubmit}
                >
                  <span>
                    Subscribe
                    <MdSend />
                  </span>
                </Submit>

              </InnerFormDiv>
            </form>
            {this.state.status === `error` && (
              <ErrorMessage
                dangerouslySetInnerHTML={{ __html: this.state.msg }}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

EmailCaptureForm.defaultProps = {
  signupMessage: `Enjoyed this post? Receive the next one in your inbox!`,
  confirmMessage: `Thank you! Youʼll receive your first email shortly.`
}

export default EmailCaptureForm
