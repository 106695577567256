import React from "react"
import BioDavidPrice from "../components/bio-david-price"
import BioKresten from "../components/bio-kresten"
import BioBarry from "../components/bio-barry"
import BioSteve from "../components/bio-steve"
import BioDavidHorner from "../components/bio-david-horner"
import BioDavidTurner from "../components/bio-david-turner"
import BioKarl from "../components/bio-karl"

import { rhythm, scale } from "../utils/typography"

class Team extends React.Component {
  render() {

    return (
      <div>
        <h2
        style = {{
          ...scale(1.25),
          marginTop: rhythm(2.25)
        }}
        >
          The <span
            style={{
              fontFamily: `Aleo, sans-serif`
            }}
          >
          human(<span style={{fontWeight: 200, color: '#0DC1ED'}}>Ethos</span>)
          </span> Collective
        </h2>

        <h3
          style={{
            marginBottom: rhythm(1),
            fontWeight: 'normal'
          }}
        >
          We are a multi-disciplinary collective organized around a passion for
          leadership and team transformation. Each member offers a unique depth
          of expertise and breadth of experience.
        </h3>

        <p>
        We partner for client engagements and workshops. We gather as a
        community to share learning, seek feedback, and deepen our
        relationships. As a group, we will be publishing articles, case
        studies, and podcasts.
        </p>

        <h3
          style = {{
            ...scale(1),
            marginBottom: rhythm(.25)
          }}
        >
          <span
            style={{
              fontFamily: `Aleo, sans-serif`
            }}
          >
            human(<span style={{fontWeight: 200, color: '#0DC1ED'}}>Core Team</span>)
          </span>
        </h3>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <BioDavidPrice />
          <BioKresten />
          <BioBarry />
        </div>

        <h3
          style = {{
            ...scale(1),
            marginBottom: rhythm(.25),
            marginTop: rhythm(.5)
          }}
        >
          <span
            style={{
              fontFamily: `Aleo, sans-serif`
            }}
          >
            human(<span style={{fontWeight: 200, color: '#0DC1ED'}}>Collective</span>)
          </span>
        </h3>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <BioSteve />
          <BioDavidHorner />
          <BioDavidTurner />
          <BioKarl />
        </div>

        <p
          style={{
            marginTop: rhythm(1.5)
          }}
        >
          If you share our passion and have interest in joining our collective,
          we would welcome a conversation to learn more about you and your work.
          Just reach out via the link below.
        </p>

      </div>
    )
  }
}

export default Team
